import {Controller} from "stimulus"
//   <!--
//     Notification panel, dynamically insert this into the live region when it needs to be displayed

//     Entering: "transform ease-out duration-300 transition"
//       From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
//       To: "translate-y-0 opacity-100 sm:translate-x-0"
//     Leaving: "transition ease-in duration-100"
//       From: "opacity-100"
//       To: "opacity-0"
//   -->

export default class extends Controller {

    static transitions = {
        entering: {
            base: 'transform ease-out duration-1000 transition',
            from: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-10',
            to: 'transform translate-y-0 opacity-100 sm:translate-x-0'
        },
        leaving: {
            base: 'transform transition ease-in duration-200',
            from: 'opacity-100',
            to: 'opacity-0 translate-y-1 sm:translate-y-0 sm:translate-x-2'
        }
    }

    connect() {
        setTimeout(() => {
            this.enter().then(() => this.autoCloseIfNeeded())
        }, 500)
    }

    get autoCloseAfter() {
        const autoClose = this.element.dataset.noticeAutoCloseAfter
        return Number(autoClose)
    }

    autoCloseIfNeeded() {
        if (this.autoCloseAfter) {
            setTimeout(() => this.leave(), this.autoCloseAfter)
        }
    }

    addClasses(list) {
        this.element.classList.add(...list.split(/\s+/))
    }

    removeClasses(list) {
        this.element.classList.remove(...list.split(/\s+/))
    }

    enter() {
        this.element.classList.remove("hidden")
        const transition = this.constructor.transitions
        return this.transition(
            transition.entering.base, 
            transition.entering.from,
            transition.entering.to)
    }

    leave() {
        const transition = this.constructor.transitions
        this.transition(
            transition.leaving.base, 
            transition.leaving.from,
            transition.leaving.to)
            .then(() => {
                this.element.classList.add("hidden")
            })
    }

    async transition(base, from, to) {
        const el = this.element
        this.addClasses(base)
        this.addClasses(from)

        return new Promise((resolver) => {
            setTimeout(() => {
                this.removeClasses(from)
                this.addClasses(to)

                setTimeout(() => {
                    this.removeClasses(to)
                    this.removeClasses(base)

                    resolver()
                }, 1001)
            }, 100)
        })
    }

    close() {
        this.leave()
    }
}