
export function padZeroes(input, length) {
  const matches = input.match(/^\d+/)
  if (matches.length > 0) {
    const number = matches[0]
    const padding = length - number.length
    let parts = []
    for (let i = 1, end = padding; i <= end; i++) {
        parts.push("0")
    }
    parts.push(input)
    return parts.join("")
  }

  return input
}

export function toQuery(data) {
    let parts = []
    for (let key in data) {
        parts.push(key + "=" + encodeURIComponent(data[key]))
    }
    return parts.join("&")
}

export function capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1)
}
