import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["radioInput"]

    connect() {
    }

    get checked() {
        return this.radioInputTarget.checked
    }

    get planId() {
        return this.element.dataset.planId
    }

    handleGroupChange(e) {
        if (e.detail == this.element) {
            return // ignore our own events
        }
        this.updateSelectionStyle()
    }

    updateSelectionStyle() {
        const activeClasses = this.element.dataset.activeClass.split(/\s+/)
        const inactiveClasses = this.element.dataset.inactiveClass.split(/\s+/)
        if (this.checked) {
            this.element.classList.add(...activeClasses)
            this.element.classList.remove(...inactiveClasses)
        } else {
            this.element.classList.remove(...activeClasses)
            this.element.classList.add(...inactiveClasses)
        }
    }

    update() {
        const changeEvent = new CustomEvent("radioGroupChange", {
            detail: this.element
        })
        window.dispatchEvent(changeEvent)
        this.updateSelectionStyle()
    }

}