import { Controller } from 'stimulus'

export default class extends Controller {

	static targets = [
		'button',
		'addCardPanel',
		'submitButton',
		'form'
	]

	connect() {
		
	}
}