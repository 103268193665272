import { Request } from "../common/request"
import StripeCardController from "./stripe_card_controller"
import Alert from "../components/alert/alert"

// This class handles the payment form on the My Account page
// most of the logic is moved up to the parent class.
export default class extends StripeCardController {
    static values = {
        cardPresent: Boolean
    }

    static targets = StripeCardController.targets.concat(['cardDisplay'])

    connect() {
        window.controller = this
        super.connect()

        if (!this.cardPresentValue) {
            this.showCardForm()
        }
    }

    async createSetupIntent() {
        if (!this.element.dataset.setupIntentUrl) {
            alert('misconfigured setup intent')
            return
        }

        const request = new Request('get', this.element.dataset.setupIntentUrl, { accept: 'json' })
        return request.perform()
            .then(r => r.json())
            .then((intent) => {
                this.setupIntent = intent
                return intent
            })
    }

    showCardForm(e) {
        if (e) {
            e.preventDefault()
        }
        this.setButtonLoading(false)

        const showForm = () => {
            this.cardFormTarget.classList.remove("hidden")
        }

        if (!this.setupIntent) {
            this.createSetupIntent()
                .then((_intent) => {
                    showForm()
                })
        } else {
            showForm()
        }
    }

    hideCardForm(e) {
        if (e) {
            e.preventDefault()
        }
        this.cardFormTarget.classList.add("hidden")
    }

    handleCardSubmit() {
        this.setButtonLoading(true)

        this.stripe.confirmCardSetup(this.setupIntent.client_secret, {
            payment_method: {
                card: this.cardElement,
                billing_details: {
                    name: this.nameOnCardTarget.value
                }
            }
        }).then((result) => {
            if (result.error) {
                this.setButtonLoading(false)
                this.showCardError(result.error.message)
            } else {
                this.setupIntent = result.setupIntent
                this.saveCard()
            }
        })
    }

    saveCard() {
        const request = new Request('post', this.element.dataset.updateUrl, {
            accept: 'json',
            contentType: 'application/json',
            body: {
                setup_intent_id: this.setupIntent.id
            }
        })
        request.perform()
            .then(r => r.json())
            .then((card) => {
                this.hideCardForm()
                this.hideCardErrors()
                this.cardDisplayTarget.innerHTML = card.html
                new Alert().showSuccess("Your card was updated.")
            })
            .catch((error) => {
                this.setButtonLoading(false)
                this.showCardError(error)
            })
    }

    removeCard() {
        this.removeCardButtonTarget.enabled = false
        const request = new Request('delete', this.element.dataset.updateUrl, {
            accept: 'json'
        })
        request.perform()
            .then((r) => {
                this.cardDisplayTarget.innerHTML = ""
                this.removeCardButtonTarget.remove()
                new Alert().showSuccess("Your card was removed.")
            })
            .catch((error) => {
                console.error(error)
                new Alert().showError("Error removing your card.")
                this.removeCardButtonTarget.enabled = true
            })
    }
}
