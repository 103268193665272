import {Controller} from "stimulus"

class StripeCardController extends Controller {
    static targets = ['cardElement', 'cardErrors', 'cardForm', 'nameOnCard', 'submitButton', 'cardErrorMessage']

    connect() {
        const stripeKey = document.querySelector('meta[name="stripe_key"]').content

        if (!this.hasCardFormTarget) throw new Error("This controller must have a cardFormTarget")
        if (!this.hasCardElementTarget) throw new Error("This controller must have a cardElement")
        if (!this.hasCardErrorsTarget) throw new Error("This controller must have a cardErrorsTarget")
        if (!this.hasNameOnCardTarget) throw new Error("This controller must have a nameOnCardTarget")
        if (!this.hasSubmitButtonTarget) throw new Error("This controller must have a submitButtonTarget")

        if (stripeKey) {
            this.stripe = Stripe(stripeKey)
            this.setupElements()
        } else {
            console.warn("Stripe key not present")
        }

        this.cardFormTarget.addEventListener('submit', (e) => {
            e.preventDefault()
            if (this.validate()) {
                this.handleCardSubmit()
            }
        })
    }

    setupElements() {
        let style = {
            base: {
                color: 'rgba(17, 24, 39, 1)',
                fontWeight: 500,
                fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                fontSmoothing: 'antialiased',
                fontSize: '19.125px',
            }
        }
        this.elements = this.stripe.elements()

        let card = this.elements.create("card", { style })
        card.mount(this.cardElementTarget)

        card.on('change', ({error}) => {
            if (error) {
                this.showCardError(error.message)
                this.submitButtonTarget.disabled = true
            } else {
                this.hideCardErrors()
                this.submitButtonTarget.disabled = false
            }
        })

        this.cardElement = card
    }

    setButtonLoading(loading) {
        let spinner = this.submitButtonTarget.querySelector(".loading-indicator")

        if (!spinner) {
            return
        }

        this.submitButtonTarget.disabled = loading
        let text = this.submitButtonTarget.querySelector(".button-text")

        if (loading) {
            text.classList.add("hidden")
            spinner.classList.remove("hidden")
        } else {
            spinner.classList.add("hidden")
            text.classList.remove("hidden")
        }
    }

    validate() {
        if (this.nameOnCardTarget.value.trim() === "") {
            this.showCardError("Please enter the name on the card")
            return false
        }
        return true
    }

    showCardError(msg) {
        this.cardErrorsTarget.classList.remove("hidden")
        this.cardErrorsTarget.textContent = msg
    }

    hideCardErrors() {
        this.cardErrorsTarget.classList.add("hidden")
        this.cardErrorsTarget.textContent = ""
        if (this.hasCardErrorMessageTarget) {
            this.cardErrorMessageTarget.classList.add("hidden")
            this.cardErrorMessageTarget.textContent = ""
        }
    }

    handleCardSubmit() {
        throw new Error("This must be overridden in a subclass")
    }
}

export default StripeCardController