class EpisodeList {
  constructor() {
    this.initializeSearch();
    this.updateWatchedIndicators();
  }

  initializeSearch() {
    let form = document.querySelector("form.form-search")
    if (!form) {
      return
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault()
      var query = document.querySelector("input.search-query").value.trim()
      if (query.length === 0) {
        window.location = "/episodes";
      } else {
        window.location = `/episodes?query=${query}`;
      }
    })
  }

  updateWatchedIndicators() {
    var watchedEpisodes = document.querySelector("input[name='user_watched_episodes']")
    if (watchedEpisodes) {
      var watchedEpisodeIds = watchedEpisodes.value.split(",");
      // show them all first
      document.querySelectorAll(".episode_watch_indicator").forEach(el => this.show(el))

      watchedEpisodeIds.forEach(e => {
        if (e.length === 0) {
          return;
        }
        // then hide the ones we've already watched
        document.querySelectorAll(`.js-episode-info[data-id='${e}'] .episode_watch_indicator`).forEach(el => {
          this.hide(el)
        })
      });
    } else {
      // not logged in, hide all indicators
      document.querySelectorAll(".episode_watch_indicator").forEach(el => {
          this.hide(el)
      })
    }
  }

  hide(el) {
    el.classList.add('hidden')
  }

  show(el) {
    el.classList.remove('hidden')
  }
}

if (document.readyState != 'loading') {
  new EpisodeList();
} else {
  document.addEventListener('DOMContentLoaded', () => new EpisodeList());
}
