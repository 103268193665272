import './message_banner.scss'

document.addEventListener('DOMContentLoaded', event => {
    function closeMessageBanner(event) {
        event.preventDefault();
        $(this).parent().slideUp();
    }
    let banner = document.querySelector(".MessageBanner--dismissable .js-MessageBanner--close")
    if (banner) {
      banner.addEventListener('click', closeMessageBanner);
    }
});
