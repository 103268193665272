import VideoPlayer from '../video_player/video_player';
import Rails from '@rails/ujs'
import { Request } from '../../common/request'

class EpisodePage {
  constructor(playerType) {
    window.page = this;
    if (playerType === 'mux') {
      console.log("using mux player")
    } else {
      this.setupVideoJSPlayer();
    }
  }

  setupVideoJSPlayer() {
    this.wrapper = document.querySelector(".video-wrapper")
    this.videoEl = document.querySelector(".video-wrapper video-js")
    if(this.videoEl) {

      if (this.videoEl.querySelectorAll("source").length == 0) {
        // no sources, fetch the mux one
        this.fetchSignedMuxVideoUrl();
        return;
      }

      this.hasSentPlayedEvent = false;
      this.player = new VideoPlayer(this.videoEl);
      this.setupVideoEvents(this.player.video);

      if(/autoplay=true/.test(location.search)) {
        this.playVideo();
      }
    } else {
      console.warn('No video player loaded...')
    }

    document.addEventListener('episodeWatched', (e) => this.wrapper.dataset.hasWatched = e.detail.hasWatched)
  }

  fetchSignedMuxVideoUrl() {
    const request = new Request("get", this.episodeUrl + "/mux", { accept: "json" })
    request.perform()
      .then(r => r.json())
      .then(r => {
        const muxUrl = r.url;
        let source = document.createElement("source");
        source.setAttribute("src", muxUrl);
        source.setAttribute("type", "application/X-mpegURL")
        this.videoEl.appendChild(source);
        this.setupVideoJSPlayer();
      });
  }

  setupVideoEvents(video) {
    video.on('play', () => this.onPlay());
  }

  hasWatched() {
    return this.wrapper.dataset.hasWatched == "true"
  }

  onPlay() {
    // if we've watched it already, no need to mark as watched again.
    if (this.hasWatched()) {
      return
    }

    var playToken = document.querySelector("#play_token").value
    this.submitWatched(true, playToken)
  }

  playVideo() {
    this.player.play();
  }

  get episodeUrl() {
    return document.querySelector(".episode-detail").dataset.episodeUrl
  }

  submitWatched(playToken = null) {
    this.notifyWatched(true)
    Rails.ajax({
      type: 'post',
      url: this.episodeUrl + "/mark_watched",
      data: {playToken}
    })
  }

  submitUnwatched() {
    this.notifyWatched(false)
    Rails.ajax({
      type: 'post',
      url: this.episodeUrl + "/mark_unwatched"
    })
  }

  notifyWatched(hasWatched) {
    const watchedEvent = new CustomEvent("episodeWatched", {
      detail: { 
        hasWatched
      },
      bubbles: true,
      cancelable: true
    })
    document.dispatchEvent(watchedEvent)
  }

  onLeave() {
    this.player.pause()
  }
}

export default EpisodePage
window.EpisodePage = EpisodePage
