import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["mobileMenu"]
    static values = {
        state: String
    }

    connect() {
    }

    open() {
        this.stateValue = 'open'
    }

    close() {
        this.stateValue = 'closed'
    }

    stateValueChanged(state) {
        if (state === "open") {
            this.mobileMenuTarget.classList.remove("hidden")
        } else {
            this.mobileMenuTarget.classList.add("hidden")
        }
    }
}