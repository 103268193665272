import Rails from "@rails/ujs"
import { Application } from "stimulus"
import { TransitionController } from "stimulus-use"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import MultiSelectController from "stimulus-multi-select"
import Flatpickr from "stimulus-flatpickr"

Rails.start()
window.Rails = Rails

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

require('flatpickr/dist/flatpickr.css')

application.register("multi-select", MultiSelectController)
application.register('flatpickr', Flatpickr)
