import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["page", "indicator"]
    static values = {
        "index": Number,
        "autoChange": Boolean,
        "autoChangeAfter": Number
    }

    connect() {
        window.carousel = this
        this.element.classList.add("transition", "transform", "ease-in-out", "duration-500")
    }

    scheduleAutoChange() {
        if (this.autoChangeTimer) {
            clearTimeout(this.autoChangeTimer)
        }

        this.autoChangeTimer = setTimeout(() => {
                this.next()
            }, this.autoChangeAfterValue || 3500)
    }

    get pageCount() {
        return this.pageTargets.length
    }

    next() {
        if (this.indexValue >= this.pageCount - 1) {
            this.indexValue = 0
        } else {
            this.indexValue++
        }
    }

    previous() {
        if (this.indexValue == 0) {
            this.indexValue = this.pageCount - 1
        } else {
            this.indexValue--
        }
    }

    setPage(e) {
        e.preventDefault()
        this.indexValue = e.target.dataset.page
    }

    indexValueChanged() {
        if (this.autoChangeTimer) {
            clearTimeout(this.autoChangeTimer)
        }

        if (this.indexValue == 0) {
            this.pageTarget.parentElement.style = "transform: none"
        } else {
            let pageWidth = this.pageTarget.offsetWidth
            this.pageTarget.parentElement.style = `transform: translateX(-${pageWidth * this.indexValue}px)`
        }
        this.indicatorTargets.forEach((indicator, index) => {
            if (index == this.indexValue) {
                indicator.classList.add('bg-violet-600')
            } else {
                indicator.classList.remove('bg-violet-600')
            }
        });

        if (this.autoChangeValue) {
            this.scheduleAutoChange()
        }
    }
}