import { Controller } from "stimulus"
import { capitalize } from "../common/utils"

const defaultSelectedTabClass = "selected-tab"

function actionMethodName(tabName) {
    return "show" + 
        tabName.split(/_/)
        .map(x => capitalize(x))
        .join('')
}

function defineShowActions(controller) {
    const controllerConstructor = controller.constructor
    const prototype = controllerConstructor.prototype

    controller._tabs.forEach(tabName => {
        if (!(actionMethodName(tabName) in prototype)) {
            prototype[actionMethodName(tabName)] = function (e) {
                e && e.preventDefault()
                this.setSelectedTab(tabName)

                if (this.selectedTabName !== this.previousTabName) {
                    this.switchToSelectedTab()
                } 
            }
        }
    })
}

export default class extends Controller {
    static targets = ["select"]
    static tabs = []   
    static classes = ["selected"]

    initialize() {
        defineShowActions(this)
    }

    connect() {
        window.tabController = this
        if (this._tabs.length > 0) {
            this._hideTabs()
            this.setSelectedTab(this._initialTabName, false)
            this._addSelectedTabClass()
        }
    }

    switchToSelectedTab() {
        this._showSelectedTabContent()
        this._addSelectedTabClass()
        this.onSelected()

        if (this.hasSelectTarget) {
            this.selectTarget.value = this.selectedTabName
        }
    }

    get selectedContent() {
        return this.findContent(this.selectedTabName)
    }

    get previousContent() {
        return this.findContent(this.previousTabName)
    }

    get selectedTab() {
        return this.findTab(this.selectedTabName)
    }

    get previousTab() {
        if (!this.previousTabName) {
            return null
        }
        return this.findTab(this.previousTabName)
    }

    findContent(tabName) {
        return this.targets.find(tabName)
    }   

    findTab(tabName) {
        if (!tabName) {
            throw new Error("missing tabName")
        }
        const selector = this.tabSelector(tabName)
        return this.element.querySelector(selector)
    }

    dropdownTabSelect(e) {
        let opt = e.target.selectedOptions[0]
        let tab = opt.value
        this.setSelectedTab(tab)
        this.switchToSelectedTab()
    }

    setSelectedTab(tabName, setPrevious = true) {
        if (setPrevious) {
            this.previousTabName = this.selectedTabName
        }
        this.selectedTabName = tabName
    }

    tabSelector(tabName) {
        return `[data-action='${this.identifier}#${actionMethodName(tabName)}']`
    }

    get selectedTabName() {
        return this.data.get('selectedTab')
    }

    set selectedTabName(tabName) {
        this.data.set('selectedTab', tabName)
    }

    get previousTabName() {
        return this.data.get('previousTab')
    }

    set previousTabName(tabName) {
        this.data.set('previousTab', tabName)
    }

    onSelected() {
        // override in subclasses
    }

    _hideTabs() {
        this._tabs.forEach(tabName => {
            if (this._initialTabName === tabName) {
                return
            }
            this._hideContent(this.targets.find(tabName))
        })
    }

    _hideContent(el) {
        el && (el.style.display = 'none')
    }

    _showContent(el) {
        el && (el.style.display = "")
    }

    get _selectedTabClass() {
        return (
            this.element.dataset.selectedTabClass ||
            this.constructor.selectedTabClass ||
            defaultSelectedTabClass
        ).split(/\s+/)
    }

    _showSelectedTabContent() {
        this._showContent(this.selectedContent)
        this._hideContent(this.previousContent) 
    }

    _addSelectedTabClass() {
        this.selectedTab.classList.add(...this.selectedClass.split(/\s+/))
        this._removeSelectedTabClassFromPreviousTab()
    }

    _removeSelectedTabClassFromPreviousTab() {
        this.previousTab && this.previousTab.classList.remove(...this.selectedClass.split(/\s+/))
    }

    get _initialTabName() {
        return this.selectedTabName || this._tabs[0]
    }

    get _tabs() {
        const tabsDefinedOnElement = this.element.dataset.tabs
        if (tabsDefinedOnElement) {
            return tabsDefinedOnElement.trim().split(/\s+/)
        } else {
            return this.constructor.tabs
        }
    }
}

