import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ["field", "priceLabel", "discountedPriceLabel", "priceInfoContainer", "emailForPlan"]   
    static values = { seats: Number, teamPlans: Object }

    static minSeats = 2
    static maxSeats = 50

    connect() {
        window.controller = this
        this.updateField()
    }

    increment() {
        this.seatsValue++
    }

    decrement() {
        this.seatsValue--
    }

    seatsValueChanged() {
        if (this.seatsValue < this.constructor.minSeats) {
            this.seatsValue = this.constructor.minSeats
        }
        
        if (this.seatsValue > this.constructor.maxSeats) {
            this.priceInfoContainerTarget.classList.add('hidden')
            this.emailForPlanTarget.classList.remove('hidden')
        } else {
            this.priceInfoContainerTarget.classList.remove('hidden')
            this.emailForPlanTarget.classList.add('hidden')
        }

        this.updateField()

        let plan = this.teamPlansValue[this.seatsValue.toString()]
        if (plan) {
            this.updatePrice(plan)
            let planChangedEvent = new Event('planChanged')
            planChangedEvent.plan = plan
            this.element.dispatchEvent(planChangedEvent)
        }
    }

    fieldUpdated(e) {
        let seats = parseInt(e.target.value) || this.constructor.minSeats
        this.fieldTarget.value = seats.toString()
        this.seatsValue = seats
    }

    updateField() {
        this.fieldTarget.value = this.seatsValue.toString()
    }

    updatePrice(plan) {
        let monthlyPriceInDollars = plan.amount_monthly / 100
        this.priceLabelTarget.innerText = monthlyPriceInDollars.toString()
        if (window.discountFunction) {
            this.priceLabelTarget.classList.add('text-gray-500', 'line-through')
            this.discountedPriceLabelTarget.classList.remove('hidden')
            this.discountedPriceLabelTarget.innerText = window.discountFunction(monthlyPriceInDollars).toFixed(2)
        } else {
            this.discountedPriceLabelTarget.classList.add('hidden')
            this.priceLabelTarget.classList.remove('text-gray-500', 'line-through')
        }
    }
}