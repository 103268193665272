import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["button", "nub", "hiddenField"]
    static values = {
        on: Boolean
    }

    connect() {
    }

    onValueChanged() {
        this.buttonTarget.ariaChecked = this.onValue;

        if (this.hasHiddenFieldTarget) {
            this.hiddenFieldTarget.value = this.onValue;
        }

        [this.buttonTarget, this.nubTarget].forEach(el => {
            const onClass = el.dataset.onClass.split(/\s+/)
            const offClass = el.dataset.offClass.split(/\s+/)
            el.classList.remove(...(this.onValue ? offClass : onClass))
            el.classList.add(...(this.onValue ? onClass : offClass))
        })
    }

    toggle() {
        this.onValue = !this.onValue
    }
}