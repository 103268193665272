import StripeCardController from "./stripe_card_controller"

// This handles logic for setting up a card for a new subscription.
// most of the logic for handling card details are handled in the parent class.
export default class extends StripeCardController {
    static values = {
        clientSecret: String
    }

    connect() {
        super.connect()
        window.controller = this
    }

    handleCardSubmit() {
        this.setButtonLoading(true)
        this.stripe.confirmCardPayment(this.clientSecretValue, {
            payment_method: {
                card: this.cardElement,
                billing_details: { name: this.nameOnCardTarget.value }
            }
        })
            .then((result) => {
                if (result.error) {
                    this.cardErrorsTarget.classList.remove("hidden")
                    this.cardErrorsTarget.textContent = `Payment failed: ${result.error.message}`
                    this.setButtonLoading(false)
                } else {
                    console.log("Payment succeeded")
                    window.location.href = "/users/me/subscriptions/verify"
                }
            })
    }
}
