import {Controller} from "stimulus"

export default class extends Controller {

    get identifier() {
        return this.element.dataset.modalIdentifier
    }

    get triggers() {
        return document.querySelectorAll(`[data-toggle='modal'][data-modal-id='${this.identifier}']`)
    }

    initialize() {
        this.triggerClickCallback = this.show.bind(this)

        document.addEventListener('modal.show', (e) => {
            if (e.detail && e.detail.modalIdentifier === this.identifier) {
                this.show()
            }
        })
    }

    connect() {
        window.modal = this
        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', this.triggerClickCallback)
        })
    }

    disconnect() {
        this.triggers.forEach(trigger => {
            trigger.removeEventListener('click', this.triggerClickCallback)
        })
    }

    show() {
        this.element.classList.remove("hidden")
    }

    hide() {
        this.element.classList.add("hidden")
    }
}