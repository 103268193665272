import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "menu", "trigger" ]

    toggle(event) {
        event.preventDefault()
        const isOpen = this.menuTarget.style.display == 'block'
        if (isOpen) {
            this.hide(null)
        } else {
            this.open()
        }
    }

    open() {
        this.menuTarget.setAttribute('aria-expanded', 'true')
        this.menuTarget.style.display = 'block'
    }

    hide(event) {
        // prevent clicks in our own trigger from hiding
        if (event && (this.triggerTarget.contains(event.target))) {
            // skip hiding
            return
        }

        this.menuTarget.setAttribute('aria-expanded', 'false')
        this.menuTarget.style.display = 'none'
    }
}