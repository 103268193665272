import { getCookie, setCookie } from './cookies'
import { Response } from './response'

export class Request {
    constructor(method, url, options = {}) {
        this.method = method
        this.url = url
        this.options = options
    }

    async perform() {
        const response = new Response(await fetch(this.url, this.fetchOptions))
        return response
    }

    get fetchOptions() {
        return {
            method: this.method,
            headers: this.headers,
            body: this.body,
            signal: this.signal,
            credentials: "same-origin",
            redirect: "follow"
        }
    }

    get headers() {
        return compact({
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token":     this.csrfToken,
            "Content-Type":     this.contentType,
            "Accept":           this.accept
        })
    }

    get csrfToken() {
        const csrfParam = document.head.querySelector("meta[name=csrf-param]")?.content
        if (csrfParam === 'authenticity_token') {
            const token = document.head.querySelector("meta[name=csrf-token]")?.content
            if (token) {
                return token
            }
        } else {
            console.log("CSRF Param", csrfParam)
        }
        return csrfParam ? getCookie(csrfParam) : undefined
    }

    get contentType() {
        if (this.options.contentType) {
            return this.options.contentType
        } else if (this.options.body == null || this.options.body instanceof FormData) {
            return undefined
        } else if (this.options.body instanceof File) {
            return this.options.body.type
        } else {
            return "application/octet-stream"
        }
    }

    get accept() {
        switch (this.options.accept || "html") {
            case "html":
                return "text/html, application/xhtml+xml"
            case "json":
                return "application/json"
            default:
                return "*/*"
        }
    }

    get body() {
        if (this.options.contentType === 'application/json') {
            return JSON.stringify(this.options.body)
        }
        return this.options.body
    }

    get signal() {
        return this.options.signal
    }
}

function compact(object) {
    const result = {}
    for (const key in object) {
        const value = object[key]
        if (value !== undefined) {
            result[key] = value
        }
    }
    return result
}