import ToggleController from "./toggle_controller"

export default class extends ToggleController {
	initialize() {
		super.initialize()
		document.addEventListener('episodeWatched', (e) => {
			this.stateValue = e.detail.hasWatched ? 'on' : 'off'
		})
	}

    setToggleState(state) {
		const hasWatched = state == 'on'
		let event = new CustomEvent('episodeWatched', {
			detail: { hasWatched },
			bubbles: true,
			cancelable: true
		})
		document.dispatchEvent(event)
		super.setToggleState(state)
	}
}