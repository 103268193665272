import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["seatSelector", "selectPlanLink"]

    connect() {
        window.foo = this
        this.seatSelectorTarget.addEventListener('planChanged', this.planChange.bind(this))
    }

    planChange(e) {
        this.selectPlanLinkTarget.dataset.planId = e.plan.plan_id
    }

    signupClicked(e) {
        e.preventDefault()
        let link = this.selectPlanLinkTarget
        if (!link.dataset.planId) {
            alert('Please select a plan')
            return
        }

        let url = link.href + `?plan_id=${link.dataset.planId}`
        window.location = url
    }
}