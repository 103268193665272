import AlertTriangleImage from './alert-triangle.svg'
import './alert.scss'

class Alert {
    showSuccess(text) {
        this.showAlert(text, 'success')
    }

    showError(text) {
        this.showAlert(text, 'error')
    }

    showAlert(text, icon) {
        let template = document.querySelector("#alert-template").innerHTML
        let iconHTML = document.querySelector(`#alert-icon-${icon}`).innerHTML
        let alert = document.createElement("div")
        alert.innerHTML = template.replace("{{message}}", text)
                                  .replace("{{icon}}", iconHTML)
        let container = document.querySelector(".alert-container")
        container.appendChild(alert)
    }
}

export default Alert

window.Alert = Alert
