import {Controller} from "stimulus"

export default class extends Controller {
    static targets = [
        "muxAssetSelect",
        "muxAssetId",
        "muxPlaybackId",
        "muxPlaybackPolicy"
       ]

    connect() {
        this.muxAssetSelectTarget.addEventListener('change', () => this.quickSelectMuxAsset())
    }

    quickSelectMuxAsset() {
      const opt = this.muxAssetSelectTarget.selectedOptions[0]
      const assetId = opt.value
      const playbackId = opt.dataset.playbackId || ''

      this.muxAssetIdTarget.value = assetId
      this.muxPlaybackIdTarget.value = playbackId
      const isSigned = playbackId.length
      const policyValue = isSigned ? 'signed' : 'public'

      this.muxPlaybackPolicyTargets.filter(x => x.value === policyValue)[0].checked = true
    }
}