import { Controller } from "stimulus"

export default class extends Controller {
    static values = {
        state: String
    }

    static targets = ['toggleOn', 'toggleOff']

    initialize() {
        this.updateToggleVisibility()
        this.handleToggleClick(this.toggleOnTarget, 'on')
        this.handleToggleClick(this.toggleOffTarget, 'off')
    }

    handleToggleClick(el, state) {
        el.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            const link = e.currentTarget
            const method = link.dataset.method || 'post'
            this.setToggleState(state)
            this.sendRequest(link.href, method)
        })
    }

    setToggleState(state) {
        this.stateValue = state
    }

    sendRequest(url, method) {
        window.Rails.ajax({
            url: url,
            type: method,
            success: (r) => console.log(r),
            error: (e) => console.error(e)
        })
    }

    updateToggleVisibility() {
        this.hideElement(this.stateValue == 'on' ? this.toggleOnTarget : this.toggleOffTarget)
        this.showElement(this.stateValue == 'off' ? this.toggleOnTarget : this.toggleOffTarget)
    }

    stateValueChanged() {
        this.updateToggleVisibility()
    }

    hideElement(el) {
        el.classList.add("hidden")
    }

    showElement(el) {
        el.classList.remove("hidden")
    }
}