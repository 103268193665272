import './transitions.scss'

// Some transitions happen on load and we don't want them to. Add .skip-initial-transition to these elements
// and it will disable the transition. We'll then remove these classes shortly after the DOM is loaded.
document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.querySelectorAll('.skip-initial-transition').forEach(el => {
            el.classList.remove('skip-initial-transition')
        })
    }, 100)
})