import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "menu", "button", "svgOpen", "svgClose" ]
    static values = { open: Boolean }

    toggle(event) {
        event.preventDefault()
        const isOpen = this.menuTarget.style.display == 'block'
        if (isOpen) {
            this.close()
        } else {
            this.open()
        }
    }
    open() {
        this.openValue = true
    }
    close() {
        this.openValue = false
    }

    openValueChanged() {
        if (this.openValue) {
            this.menuTarget.setAttribute('aria-expanded', 'true')
            this.menuTarget.style.display = 'block'
            this.svgOpenTarget.classList.replace('block', 'hidden')
            this.svgCloseTarget.classList.replace('hidden', 'block')
        } else {
            this.menuTarget.setAttribute('aria-expanded', 'false')
            this.menuTarget.style.display = 'none'
            this.svgOpenTarget.classList.replace('hidden', 'block')
            this.svgCloseTarget.classList.replace('block', 'hidden')
        }
    }
}