import AirplayPlugin from "./airplay.js"

class VideoPlayer {
    constructor(el) {
        AirplayPlugin();
        this.el = el;
        this.setupVideoJS();
    }

    setupVideoJS() {
        this.video = videojs(this.el, {
            playbackRates: [0.5, 1.0, 1.25, 1.4, 1.5, 1.7, 1.8, 2.0],
            plugins: {
                // airPlay: {},
            },
            html5: {
                hls: {}
            }
        });

        this.setupMuxData(this.video);

        this.video.ready(function() {
            this.hotkeys({
                seekStep: 10,
                enabledModifiersForNumbers: false,
                enableVolumeScroll: false
            });
        });

        this.video.on('contextmenu', e => e.preventDefault());
    }

    setupMuxData(vjs) {
        const playerInitTime = Date();
        const videoId = this.el.getAttribute('data-video-id');
        const videoTitle = this.el.getAttribute('data-video-title');
        const videoSeries = this.el.getAttribute('data-video-series');

        try {
            vjs.mux({
                debug: false,
                data: {
                    env_key: process.env.MUX_ENVIRONMENT_KEY,

                    // Metadata
                    player_name: 'Web Player',
                    player_init_time: playerInitTime,

                    video_id: videoId,
                    video_title: videoTitle,
                    video_series: videoSeries
                }
            })
        } catch (err) {
            // video will play, but no analytics
            console.error("Failed to load mux data: ", err)
        }
    }

    play() {
        this.video.play();
    }

    pause() {
        this.video.pause();
    }
}

export default VideoPlayer;
