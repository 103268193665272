import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["label"]

    connect() {
        this.input = this.element.querySelector("input[type='file']")
        if(this.input) {
            this.input.addEventListener('change', () => this.handleFileChange())
        } else {
            console.warn("Missing file input")
        }
    }

    handleFileChange() {
        const file = this.input.files[0]
        if (file) {
            this.labelTarget.innerText = file.name
        } else {
            this.labelTarget.innerText = "Choose File..."
        }
    }
}