import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["changePlanButton", "changePlanForm", "saveButton"]

    connect() {
        if (this.currentPlanId === "") {
            this.showForm()
        }
    }

    get currentPlanId() {
        return this.changePlanFormTarget.dataset.currentPlanId
    }

    get selectedPlanRadioInput() {
        return this.changePlanFormTarget.querySelector("input[name='plan_id']:checked")
    }

    onChange() {
        if (this.selectedPlanRadioInput.value == this.currentPlanId) {
            this.saveButtonTarget.classList.add("opacity-25")
            this.saveButtonTarget.disabled = true
        } else {
            this.saveButtonTarget.classList.remove("opacity-25")
            this.saveButtonTarget.removeAttribute('disabled')
        }
    }

    showForm() {
        this.changePlanButtonTarget.classList.add("hidden")
        this.changePlanFormTarget.classList.remove("hidden")
    }

    hideForm() {
        this.changePlanButtonTarget.classList.remove("hidden")
        this.changePlanFormTarget.classList.add("hidden")
    }
}