import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        "planPicker",
        "title",
        "priceCards"
    ]

    connect() {
        if (!this.readPlanFromURL()) {
            let plan = this.selectedPlan
            this.selectedPlan = plan
        }
    }

    changePlan(e) {
        let plan = e.target.dataset.plan
        this.selectedPlan = plan
    }

    readPlanFromURL() {
        const validPlans = ['individual', 'team']
        let hashPlan = window.location.hash.substring(1) // remove #
        if (validPlans.indexOf(hashPlan) >= 0) {
            this.selectedPlan = hashPlan
            return true
        }

        return false
    }

    get selectedPlan() {
        return this.element.dataset.plan
    }

    set selectedPlan(plan) {
        this.element.dataset.plan = plan
        window.location.hash = `#${plan}`

        this.updatePlanTitle(plan)
        this.updatePlanPickerButton(plan)
        this.updatePriceCards(plan)
    }

    updatePlanTitle(plan) {
        let individualTitle = this.titleTargets.filter(x => x.dataset.plan === 'individual')[0]
        let teamTitle = this.titleTargets.filter(x => x.dataset.plan === 'team')[0]
        if (plan === 'individual') {
            individualTitle.classList.remove("hidden", "opacity-0", "-translate-x-60")
            individualTitle.classList.add("opacity-100")

            teamTitle.classList.remove("opacity-100")
            teamTitle.classList.add("opacity-0", "translate-x-60")
        } else {
            individualTitle.classList.remove("opacity-100")
            individualTitle.classList.add("opacity-0", "-translate-x-60")

            teamTitle.classList.remove("hidden", "opacity-0", "translate-x-60")
            teamTitle.classList.add("opacity-100")
        }
    }

    updatePlanPickerButton(plan) {
        this.planPickerTargets.forEach(pickerButton => {
            if (pickerButton.dataset.plan == plan) {
                pickerButton.classList.remove('text-white')
                pickerButton.classList.add('bg-white', 'text-gray-900')
            } else {
                pickerButton.classList.add('text-white')
                pickerButton.classList.remove('bg-white', 'text-gray-900')
            }
        });
    }

    updatePriceCards(plan) {
        this.priceCardsTargets.forEach(card => {
            // because these elements can use a grid utility, we can't just toggle the hidden utility
            // instead, use inline styles which will override the grid style
            if (card.dataset.plan == plan) {
                card.style = ''
            } else {
                card.style = 'display: none'
            }
        })
    }
}

