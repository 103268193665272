import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["email", "password", "passwordConfirm", "errorMessage"]

    connect() {
        window.controller = this
    }

    validateEmail() {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const email = this.emailTarget.value.trim()

        if (email.length == 0) {
            this.errorMessageTarget.innerText = "Required"
            return false
        }

        if (!emailRegex.test(email)) {
            this.errorMessageTarget.innerText = "Invalid email"
            return false
        }

        return true
    }

    validateMatchingPasswords() {
        const pass = this.passwordTarget.value
        const passConfirm = this.passwordConfirmTarget.value

        if (pass.length == 0) {
            this.errorMessageTarget.innerText = "Password Required"
            return false
        }

        if (pass != passConfirm) {
            this.errorMessageTarget.innerText = "Passwords don't match"
            return false
        }

        return true
    }

    onSubmitChangePassword(e) {
        this.errorMessageTarget.classList.add("hidden")
        if (!this.validateMatchingPasswords()) {
            this.errorMessageTarget.classList.remove("hidden")
            e.preventDefault()
        }
    }

    onSubmitReset(e) {
        this.errorMessageTarget.classList.add("hidden")
        if (!this.validateEmail()) {
            this.errorMessageTarget.classList.remove("hidden")
            e.preventDefault()
        }
    }
}