import {Controller} from "stimulus"
import Rails from "@rails/ujs"
import {padZeroes, toQuery} from "../../common/utils"

export default class extends Controller {
    static targets = ["slug", "slugBased", "title", "episodeNumber"]
    static values = {
        isPersisted: Boolean,
        suggestSlugUrl: String
    }

    connect() {
        window.episodeForm = this
        this.slugTarget.addEventListener('change', () => this.slugChanged())
        this.titleTarget.addEventListener('change', () => this.titleChanged())
    }

    get slug() {
        return this.slugTarget.value.trim()
    }

    suggestSlug() {
        const title = this.titleTarget.value

        Rails.ajax({
            type: 'get',
            url: this.suggestSlugUrlValue,
            data: toQuery({
                episode_number: this.episodeNumberTarget.value,
                title
            }),
            success: (data) => {
                this.slugTarget.value = padZeroes(data, 3)
                this.slugChanged()
            },
            error: (err) => console.error(err)
        })
    }

    titleChanged() {
        if (this.isPersistedValue) {
            // don't want to override a saved slug
            return
        }

        if (this.slug === "") {
            this.suggestSlug()
        }
    }

    slugChanged() {
        this.slugBasedTargets.forEach(t => {
            if (this.slug === "") {
                t.value = ""
            } else {
                const format = t.dataset.slugFormat
                t.value = format.replaceAll("{{slug}}", this.slug)
            }
        })
    }
}