import smoothscroll from 'smoothscroll-polyfill'


// Safari 14 has this feature disabled by default, so we polyfill it
smoothscroll.polyfill()

function setupAnimatedScrolling() {
  let links = document.querySelectorAll(".js-scroll-link")
  links.forEach(link => {
    link.addEventListener('click', function(e) {
      if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
        let anchorName = this.hash
        let target = document.querySelector(anchorName) || document.querySelector(`[name='${this.hash.slice(1)}']`)
        if (target) {
          e.preventDefault()
          window.scroll({
            top: target.offsetTop,
            behavior: 'smooth'
          })
          return false
        }
      }
    })
  })
}

if (document.readyState != 'loading') {
  setupAnimatedScrolling()
} else {
  document.addEventListener('DOMContentLoaded', setupAnimatedScrolling)
}
