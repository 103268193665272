import {Controller} from "stimulus"


// Controls visibility of an element, either through data-action handlers (show, hide, toggle)
// or by binding to the state of a checkbox
export default class extends Controller {
    static targets = ["item", "checkbox", "select", "radio"]
    static values = {
        activeSelect: String,
        mode: String  // hide|disable, default is to hide
    }

    connect() {
        if (!this.modeValue) {
            this.modeValue = "hide"
        }

        if (this.hasCheckboxTarget) {
            this.checkboxTarget.addEventListener('change', (e) => this.updateFromCheckbox())
            this.updateFromCheckbox()
        }

        if (this.hasSelectTarget) {
            this.selectTarget.addEventListener('change', (e) => this.updateFromSelect())
            this.updateFromSelect()
        }

        if (this.hasRadioTarget) {
            this.radioTargets.forEach(r => {
                r.addEventListener('change', (e) => this.updateFromRadio(r))
                this.updateFromRadio(r)
            })
        }
    }

    toggle(el) {
        if (!el) {
            el = this.itemTarget
        }
        el.classList.toggle("hidden")
        if (this.modeValue == "hide") {
            el.classList.toggle("hidden")
        } else if (this.modeValue == "disable") {
            el.disabled = !el.disabled
        } else {
            console.warn(`Unknown mode value: ${this.modeValue}`)
        }
    }

    hide(el) {
        if (!el) {
            el = this.itemTarget
        }
        if (this.modeValue == "hide") {
            el.classList.add("hidden")
        } else if (this.modeValue == "disable") {
            el.disabled = true
        } else {
            console.warn(`Unknown mode value: ${this.modeValue}`)
        }
    }

    show(el) {
        if (!el) {
            el = this.itemTarget
        }
        if (this.modeValue == "hide") {
            el.classList.remove("hidden") 
        } else if (this.modeValue == "disable") {
            el.disabled = false
            el.focus()
        } else {
            console.warn(`Unknown mode value: ${this.modeValue}`)
        }
    }

    updateFromCheckbox() {
        if (this.checkboxTarget.checked) {
            this.show()
        } else {
            this.hide()
        }
    }

    updateFromSelect() {
        if (this.selectTarget.value == this.activeSelectValue) {
            this.show()
        } else {
            this.hide()
        }
    }

    updateFromRadio(radio) {
        const radioItem = radio.parentElement.querySelector("[data-radio-item]")
        if (!radioItem) {
            return 
        }
        if (radio.checked) {
            this.show(radioItem);

            // hide others

            [...this.element.querySelectorAll("[data-radio-item]")]
                .filter(x => x !== radioItem)
                .forEach(x => this.hide(x))
        } else {
            this.hide(radioItem)
        }
    }
}