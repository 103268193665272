import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["watchedIds", "episode"]

    connect() {
        if (this.hasWatchedIdsTarget) {
            const ids = this.watchedIdsTarget.value.trim().split(",")
            this.episodeTargets.forEach(ep => {
                if (ids.indexOf(ep.dataset.id) == -1) {
                    this.addIndicator(ep)
                }
            })
        }
    }

    addIndicator(el) {
        const indicator = el.querySelector(".indicator")
        indicator.classList.add("transition", "ease", "duration-500")
        indicator.classList.remove("opacity-0")

        el.querySelector(".episode-link").classList.add("font-medium")
    }
}

