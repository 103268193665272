import { Controller } from "stimulus"
import { getCookie, createCookie } from "../common/cookies"

export default class extends Controller {

    get bannerIdentifier() {
        return this.element.dataset.bannerIdentifier
    }

    get dismissedCookieName() {
        return `_dismissed_${this.bannerIdentifier}`
    }

    connect() {
        window.getCookie = getCookie
        console.log("Banner connected", this.bannerIdentifier)
        if (!this.hasDismissedBanner) {
            this.element.classList.remove('hidden')
        }
    }

    close() {
        createCookie(this.dismissedCookieName, true, 3)
        this.element.remove()
    }

    get hasDismissedBanner() {
        return getCookie(this.dismissedCookieName) != ""
    }
}
    